import { graphql } from "gatsby"
import "../stylesheets/general.css"
import React from "react"
import {
  BenefitsCard,
  Button,
  Card,
  CareerVideos,
  Content,
  Gallery,
  Hero,
  Layout,
  PositionCard,
  Subscribe,
  Video,
} from "../components"
import CareersGem from "../components/CareersGem"
import CareersMel from "../components/CareersMel"
import SEO from "../components/SEO"
import { FullWidthContainer,CareersVideoWrapper } from "../elements"

const benefits = [
  {
    title: "Bold",
    description:
      "Welcome to the incubator of courage and confidence. The dream team is ready to help you take on big ideas and make them a reality.",
  },
  {
    title: "Remarkable",
    description:
      "Being memorable means being present, and great work means a deliberate choice of outstanding over ordinary every day.",
  },
  {
    title: "Adaptable",
    description:
      "We live in a world of constant disruption; IPP is at the forefront. Plan for the unexpected, life at IPP is about agility. Flexibility required.",
  },
  {
    title: "Innovative",
    description:
      "IPP is the award-winning home of innovation. We are solution engineers intentionally designing disruptive services and experiences for exceptional results. Culture and creativity are in our DNA. Join the mission to solve old problems while mapping the possibilities of the future.",
  },
  {
    title: "Noble",
    description:
      "We are fiercely competitive - always with class. Integrity counts and we like to create in a space of mutual respect and collaboration. Beyond superior talent, we desire to do more and go further for the greater good.",
  },
]

const ServicesPage = ({ data }) => {
  const careers = data.allWordpressWpCareers.nodes
  return (
    <Layout>
      <SEO title={"Careers"} description={"At IPP, it is not how well each person performs, but how well we work together."} />
      <Hero
        title="It's An Honour, Not A Job."
        image={data.heroImage.fluid}
        videoId="nOqr06ITNI0"
        videoUrl="https://itspixelperfect.s3.amazonaws.com/videos/IPP+Christmas+-+No+TXT.mp4"
        description="At IPP, it is not how well each person performs, but how well we work together."
        hasBgVideo={true}
      />
      <Content spacing>
        <Card
          name="Our Values."
          margin="0 0 82px 0"
          grid="2x2"
          title="Winning takes talent; to repeat it takes character. How do I become the best Pixel? It’s simple -  use your BRAIN."
        >
          {benefits.map((benefit, index) => (
            <BenefitsCard key={index} benefit={benefit} />
          ))}
        </Card>
      </Content>
      <FullWidthContainer>
        <Content spacing>
          <Card
            black
            name="We Don’t Hire People, We Recruit Potential."
            margin="0 0 82px 0"
            title="Work is Wow"
            description="Happiness is a key ingredient in our formula for creativity and innovation. From flexible paid leave to premium snacks, we pay attention to the ‘little’ things that make our team happy. At IPP, we believe that a balanced life sets the foundation for success. Our team enjoys access to wellness coaching and services – gym membership and other premium benefits to support living well and good health. Everyday at IPP offers an opportunity to collaborate with some of the best and most exciting brands…every project is an adventure that you get to experience first-hand! Being the best and working with quality brands gives us rewards that we enjoy sharing with our community. If realising your full potential and helping to create breakthrough innovation are a part of your vision, welcome home.
            "
          />
        </Content>
      </FullWidthContainer>
      <Video videoId="nifguSLm2mc" thumb={data.beachThumb.fluid} />
      <Content spacing>
        <Card
          name="What our Team Has to say."
          margin="0 0 82px 0"
          title="People love it here!"
          description="Joining IPP  is  like being drafted by your favourite sports team. Here, greatness is rewarded and champions are celebrated. Life at IPP means operating in an environment where teams collaborate to  develop character, build composure under pressure for high performance and hone the skills it takes to be leaders inside and out of the company. Our values and vision align to support world-class professionals, doing exceptional work to positively  impact businesses, lives and communities across the world. 

          Hear from Melissa and Gemmar why IPP remains their company of choice.
          "
        >
         
        </Card>
      </Content>

      <CareersVideoWrapper>
        <CareersMel/> 
        <CareersGem/> 
      </CareersVideoWrapper>

      <Video videoId="n-fR1mDAJ1g" thumb={data.melThumb.fluid} buttonTitle="Watch The Clip" />
      <FullWidthContainer>
        <Content spacing>
          <Card
            black
            name="Bringing people together."
            margin="0 0 82px 0"
            title="Making history one project at a time."
            description="No day will be the same with what you do here. Our condition: do it well, do it with passion, and do it because you love it. If you’re ready to embrace challenges, redefine what’s possible and push beyond your comfort zone, we have space for you. 

            You’ll find a home among ordinary people doing extraordinary things daily. Humility in achievement is our hallmark. 
            "
          />
        </Content>
      </FullWidthContainer>
      <Gallery />
      <Content spacing>
        <Card
          name="Want to be a Pixel? Transfer window open."
          margin="0 0 82px 0"
          title="Open Positions"
          description="At IPP you get to play a major role in transforming brands and lives at every level, from the Caribbean to the world.  We’re growing and you will too. Competitors admire us, clients love to work with us and no one likes to leave. Our future is global…now signing thinkers, creatives  and visionaries."
        >
          {careers.map((career, index) => (
            <div className="positiondiv">           
              <PositionCard
              key={index}
              title={career.title}
              to={``}
              employmentType={career.acf.type_of_employment}
              location={career.acf.location}
              dueDate={career.acf.application_due_date}
              />
            <Button className="positionBtn" href={career.acf.cta_link} target="_blank" variant="solid" width="large" margin="50px 0">View details & apply</Button>
            </div>
          ))}
        </Card>
      </Content>
      <Subscribe />
    </Layout>
  )
}

export const query = graphql`
  query {
    heroImage: imageSharp(fluid: { originalName: { eq: "careershero.png" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    beachThumb: imageSharp(fluid: { originalName: { eq: "careersimg.jpg" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    melThumb: imageSharp(fluid: { originalName: { eq: "meldance3.jpg" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    allWordpressWpCareers {
      nodes {
        title
        slug
        acf {
          location
          type_of_employment
          application_due_date
          cta_link
        }
      }
    }
  }
`
export default ServicesPage

//      <Button  to={to} variant="solid" width="large" margin="50px 0">View details & apply</Button>